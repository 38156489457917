<template>
  <div>
    <div
      v-if="viewAreaTicketsPermission"
      :class="{ appLoggedOff : isLoggedOff }"
      class="appSectionContainer"
    >
      <div
        class="appSectionHeader"
      >
        <div>
          <div
            class="completedStatus"
          >
            <div class="completedStatusText">Open Tickets</div>
            <TriStateToggleSwitch
              :enabled="true"
              :current-state=" displayIncompleteTickets ? 1 : 2"
              :tri-state="false"
              @statechanged="filterTicketStatus('incomplete')"
            />
          </div>
          <div
            class="completedStatus"
          >
            <div class="completedStatusText">Waiting Tickets</div>
            <TriStateToggleSwitch
              :enabled="true"
              :current-state=" displayWaitingTickets ? 1 : 2"
              :tri-state="false"
              @statechanged="filterTicketStatus('waiting')"
            />
          </div>
          <div
            class="completedStatus"
          >
            <div class="completedStatusText">In Progress</div>
            <TriStateToggleSwitch
              :enabled="true"
              :current-state=" displayInProcessTickets ? 1 : 2"
              :tri-state="false"
              @statechanged="filterTicketStatus('inprocess')"
            />
          </div>
          <div
            class="completedStatus"
          >
            <div class="completedStatusText">Service Restored</div>
            <TriStateToggleSwitch
              :enabled="true"
              :current-state=" displayServiceRestoredTickets ? 1 : 2"
              :tri-state="false"
              @statechanged="filterTicketStatus('servicerestored')"
            />
          </div>
          <div
            class="completedStatus"
          >
            <div class="completedStatusText">Completed</div>
            <TriStateToggleSwitch
              :enabled="true"
              :current-state=" displayCompletedTickets ? 1 : 2"
              :tri-state="false"
              @statechanged="filterTicketStatus('completed')"
            />
            <div class="completedStatusText">(last 7 days)</div>
          </div>
        </div>
        <div style="padding-right: 5px;">
          <TextButton
            v-if="createTicketPermission"
            :button-tabindex="10"
            button-id="btnNewTicket"
            title="Create New Ticket"
            text="Create New"
            @click="setDialog(true)"
          />
          <TextButton
            v-if="viewAreaTicketsPermission"
            :button-tabindex="0"
            button-id="btnQueryTicket"
            title="Search through all tickets"
            text="Advanced Search"
            class="openQueryButton"
            @click="openQueryTickets()"
          />
          <dialogBox
            :dialog="dialog"
            title="Create New Ticket"
            @close="setDialog"
          >
            <createTicket
              :dialog="dialog"
              @close="setDialog"
              @show-ticket="openTicket"
            />
          </dialogBox>
        </div>
      </div>
      <div class="appSectionBody scSectionBody">
        <div
          class="priorityFilterContainer"
        >
          <div
            :class="getPriorityColour('critical')"
            class="priorityIcon"
            @click="applyFilter('critical')"
          >
            Critical {{ `(${getTicketCount('Critical')})` }}
          </div>
          <div
            :class="getPriorityColour('high')"
            class="priorityIcon"
            @click="applyFilter('high')"
          >
            High {{ `(${getTicketCount('High')})` }}
          </div>
          <div
            :class="getPriorityColour('medium')"
            class="priorityIcon"
            @click="applyFilter('medium')"
          >
            Medium {{ `(${getTicketCount('Medium')})` }}
          </div>
          <div
            :class="getPriorityColour('low')"
            class="priorityIcon"
            @click="applyFilter('low')"
          >
            Low {{ `(${getTicketCount('Low')})` }}
          </div>
          <div
            :class="getPriorityColour('changes')"
            class="priorityIcon"
            @click="applyFilter('changes')"
          >
            Changes {{ `(${getTicketCount('Changes')})` }}
          </div>
          <div
            :class="getPriorityColour('ServiceRequest')"
            class="priorityIcon"
            @click="applyFilter('ServiceRequest')"
          >
            Service Request {{ `(${getTicketCount('ServiceRequest')})` }}
          </div>
          <div
            :class="getPriorityColour('')"
            class="priorityIcon"
            @click="applyFilter(undefined)"
          >
            All {{ `(${getTicketCount('')})` }}
          </div>
        </div>
        <div
          :class="{mdHalfScreen : !!selectedTicket}"
          class="mdTickets"
        >
          <div
            v-show="isLoading"
            class="divSpinner"
          >
            <img
              :src="maintelSpinnerImage"
              alt="loading tickets, please wait..."
            >
            <p class="pSpinnerText">loading tickets, please wait...</p>
          </div>
          <table
            v-show="hasTickets && !isLoading"
            id="ticketsTable"
          >
            <thead />
            <tbody>
              <tr
                v-for="v in currentFilter"
                :key="v.id"
                :class="ticketClass(selected == v.id, v.Priority)"
                :tabindex="20"
                @click="selectTicket(v)"
                @keyup="testSelectTicket($event, v)"
              >
                <td :title="v.Ticket_Number">{{ v.Ticket_Number }}</td>
                <td :title="v.Priority">{{ v.Priority }}</td>
                <td
                  :title="v.Title">{{ v.Title }}
                </td>
                <td
                  :title="v.Customer_Reference">{{ v.Customer_Reference }}
                </td>
                <td
                  :title="v.Date_Added">{{ v.Date_Added }}
                </td>
                <td
                  :title="v.Last_Activity">{{ v.Last_Activity }}
                </td>
                <td :title="v.Status">{{ v.Status }}</td>
              </tr>
            </tbody>
          </table>
          <div
            v-show="!hasTickets && !isLoading"
            class="noResults"
          >
            Can’t see your ticket? Call our service desk on 0344 871 1122 (option 2 for support)
          </div>
        </div>
        <div
          v-if="!!selectedTicket"
          class="mdSelection"
        >
          <ticketDetailsComponent
            :ticket="selectedTicket"
            :full-ticket="selectedTicketFull"
            :show-details="showDetails"
            :show-new-notes="true"
          />
        </div>
      </div>
    </div>
    <div
      v-if="!viewAreaTicketsPermission && !isLoading"
      class="noResults"
    >
      Sorry, you do not have permission to view tickets.
    </div>
  </div>
</template>

<script>
import { ticketMixin } from '../mixins/ticket'
import tableHandler from '../mixins/tableHandler'
import { permissionsMixin } from '../mixins/permissions'
import { mapActions } from 'vuex'
import { DataEventBus } from '../events/dataEvents'
import { autotaskServiceMixin } from '../mixins/autotaskServiceMixin'
import ticketDetailsComponent from '../components/tickets/ticketDetailsComponent'
import dialogBox from '../components/common/dialogBox'
import createTicket from '../components/tickets/createTicket'
import TriStateToggleSwitch from '../components/common/triStateToggleSwitch'
import TextButton from '../components/common/textButton.vue'

export default {
  metaInfo: {
    title: 'icon portal - Tickets'
  },
  components: {
    ticketDetailsComponent,
    dialogBox,
    createTicket,
    TriStateToggleSwitch,
    TextButton
  },
  mixins: [
    ticketMixin,
    tableHandler,
    permissionsMixin,
    autotaskServiceMixin
  ],
  data () {
    return {
      visibleTickets: [],
      selectedTicket: null,
      selectedTicketFull: null,
      selected: null,
      ticketData: [],
      dialog: false,
      displayCompletedTickets: false,
      displayIncompleteTickets: true,
      displayWaitingTickets: true,
      displayInProcessTickets: true,
      displayServiceRestoredTickets: true,
      loadingTickets: false,
      showDetails: false
    }
  },
  computed: {
    currentTicketUDFs () {
      return this.$store.getters.ticketUDFs
    },
    isLoggedOff () {
      return this.$store.getters.loggedOff
    },
    viewAreaTicketsPermission () {
      return this.$store.getters.viewAreaTicketsPermission
    },
    updateTicketPermission () {
      return this.$store.getters.updateTicketPermission
    },
    createTicketPermission () {
      return this.$store.getters.createTicketPermission
    },
    autotaskIcon () {
      return require(`../assets/icons/autotask-icon.png`)
    },
    currentTickets () {
      return this.$store.getters.tickets
    },
    currentSites () {
      return this.$store.getters.sites
    },
    hasTickets () {
      return this.visibleTickets.length !== 0
    },
    isLoading () {
      if (this.currentTickets) {
        return this.currentTickets.length !== 0 && this.loadingTickets
      } else {
        return false
      }
    },
    maintelSpinnerImage () {
      return `/${this.$store.getters.channelCompany.imageRoot}/${this.$store.getters.spinnerImage}`
    },
    ticketLink () {
      if (this.$route.query.ticket) {
        return this.$route.query.ticket
      } else {
        return ''
      }
    },
    useAltColour () {
      var styles = this.$store.getters.entityStyles
      var rtn = true
      if (styles.length > 0) {
        for (let i = 0; i < styles.length; i++) {
          if (styles[i].styleName === 'alt-colour') {
            if (styles[i].styleValue === 'false') {
              rtn = false
            }
          }
        }
      }
      return rtn
    }
  },
  watch: {
    viewAreaTicketsPermission () {
      if (!this.loadingTickets) {
        this.computeTickets()
      }
    },
    currentTickets () {
      this.computeTickets()
    },
    currentSites () {
      this.computeTickets()
    },
    ticketsFilter () {
      this.computeTickets()
    }
  },
  created () {
    this.checkPermission()
  },
  mounted () {
    DataEventBus.$on('refresh-data', () => {
      this.visibleTickets = this.ticketData
      this.setUpTable(this.visibleTickets)
      if (this.ticketLink.length > 0) {
        this.openLinkedTicket()
      }
    })
    DataEventBus.$on('company-changed', () => {
      this.setTickets([])
      this.visibleTickets = []
      this.selectedTicket = null
    })
    DataEventBus.$on('loading-tickets', () => {
      this.loadingTickets = true
    })
    DataEventBus.$on('tickets-loaded', () => {
      this.computeTickets()
      this.loadingTickets = false
    })
    this.setUpTable([])
    this.computeTickets()
    DataEventBus.$emit('still-active')
  },
  methods: {
    ...mapActions([
      'setPage',
      'setTickets'
    ]),
    setUpTable (data) {
      this.initialiseTable('ticketsTable',
        data,
        ['Ticket_Number', 'Priority', 'Title', 'Customer_Reference', 'Date_Added', 'Last_Activity', 'Status'],
        ['Ticket Number', 'Priority', 'Title', 'Customer Ref', 'Date Added', 'Last Activity', 'Status'],
        ['', '', '', '', 'Date', 'Date', ''],
        null,
        ['13%', '12%', '25%', '12%', '13%', '13%', '12%'],
        null,
        true,
        'maintel_tickets.csv'
      )
    },
    selectTicket: async function (selectedRecord) {
      this.showDetails = false
      this.selectedTicketFull = this.$store.getters.tickets.filter(ticket => ticket.id === selectedRecord.id)[0]
      if (this.selectedTicketFull.createdByContactId > 0) {
        var contact = await this.getContactById(this.selectedTicketFull.createdByContactId)
        if (contact.length > 0) {
          selectedRecord.Contact_Name = contact[0].firstName + ' ' + contact[0].lastName
        } else {
          selectedRecord.Contact_Name = 'Not Resolved (' + this.selectedTicketFull.createdByContactId + ')'
        }
      } else {
        selectedRecord.Contact_Name = 'Unknown'
      }
      this.selectedTicketFull.createdByName = selectedRecord.Contact_Name
      this.selectedTicket = selectedRecord
      if (this.selected === selectedRecord.id) {
        this.selected = null
        this.selectedTicket = null
      } else {
        this.selected = selectedRecord.id
      }
      // DataEventBus.$emit('still-active')
    },
    testSelectTicket (event, selectedRow) {
      if (event.keyCode === 32) {
        this.selectTicket(event, selectedRow)
      }
    },
    computeTickets () {
      this.ticketData = []
      if (this.viewAreaTicketsPermission) {
        if (this.ticketList) {
          var selectedPriorityList = this.getSelectedPriority()
          var excludedStatusList = this.getExclusionStatusList()
          this.ticketList
            .filter(t => selectedPriorityList.includes(t.priorityId) && !excludedStatusList.includes(t.statusId))
            .forEach(element => {
              var _tempTicket = {
                id: element.id,
                Title: element.title,
                Ticket_Number: element.ticketNumber,
                Date_Added: this.getScreenDate(element.createDateTime),
                Date_Due: this.getScreenDate(element.dueDateTime),
                Estimated_Hours: element.estimatedHours,
                Issue_Type: this.getIssueTypeFromId(element.issueTypeId),
                Sub_Issue_Type: this.getSubIssueTypeFromId(element.subIssueTypeId),
                Problem_Ticket_Id: element.problemTicketId,
                Last_Activity: this.getScreenDate(element.lastActivityDateTime),
                Status: this.getStatusFromId(element.statusId),
                Customer_Reference: this.getUDFValue(element.id, 'Customer Reference'),
                Priority: this.getPriorityFromId(element.priorityId)
              }
              if (element.accountId != null && element.accountId !== 0) {
                const siteObj = this.$store.getters.site(element.accountId)
                if (siteObj) {
                  _tempTicket.Site = siteObj.name
                }
              }
              this.ticketData.push(_tempTicket)
            })
          this.ticketData = this.ticketData.sort(this.sortBy('-Ticket_Number'))
        }
        this.visibleTickets = this.ticketData
        this.setUpTable(this.visibleTickets)
      }
    },
    checkPermission: async function () {
      this.setPage('Tickets')
      if (this.viewAreaTicketsPermission) {
        this.computeTickets()
      } else {
        this.setPage('Login')
      }
    },
    getStatusFromId (statusId) {
      var rtn = this.$store.getters.statusList.find(s => s.value === statusId.toString())
      if (rtn) {
        return rtn.name
      } else {
        return 'Unknown'
      }
    },
    getPriorityFromId (priorityId) {
      var rtn = this.$store.getters.priorityList.find(p => p.value === priorityId.toString())
      if (rtn) {
        return rtn.name
      } else {
        return 'Unknown'
      }
    },
    getIssueTypeFromId (issueTypeId) {
      if (issueTypeId) {
        var rtn = this.$store.getters.issueTypeList.find(p => p.value === issueTypeId.toString())
        if (rtn) {
          return rtn.name
        } else {
          return 'Unknown'
        }
      } else {
        return 'Unknown'
      }
    },
    getSubIssueTypeFromId (subIssueTypeId) {
      if (subIssueTypeId) {
        var rtn = this.$store.getters.subIssueTypeList.find(p => p.value === subIssueTypeId.toString())
        if (rtn) {
          return rtn.name
        } else {
          return 'Unknown'
        }
      } else {
        return 'Unknown'
      }
    },
    ticketClass (selected, priority) {
      var tClass = 'Grey'
      if (priority.indexOf('Major Incident') > -1 || priority.indexOf('Critical') > -1) {
        tClass = 'Purple'
      } else if (priority.indexOf('High') > -1) {
        tClass = 'Red'
      } else if (priority.indexOf('Low') > -1) {
        tClass = 'Green'
      } else if (priority.indexOf('Medium') > -1) {
        tClass = 'Amber'
      } else if (priority.indexOf('Change') > -1) {
        tClass = 'Brown'
      } else if (priority === 'P5 - Service Request') {
        tClass = 'Blue'
      }
      if (!this.useAltColour) {
        tClass = 'Solid' + tClass
      }
      return tClass
    },
    getPriorityColour: function (priority) {
      var isSelectedPriority = this.$route.query.ticketsFilter === priority ? 'selected' : ''
      if (this.$route.query.ticketsFilter === undefined && priority.length === 0) {
        isSelectedPriority = 'selected'
      }
      switch (priority ? priority.toLowerCase() : '') {
        case 'servicerequest':
          return 'Blue ' + isSelectedPriority
        case 'critical':
          return 'Purple ' + isSelectedPriority
        case 'high':
          return 'Red ' + isSelectedPriority
        case 'medium':
          return 'Amber ' + isSelectedPriority
        case 'low':
          return 'Green ' + isSelectedPriority
        case 'changes':
          return 'Brown ' + isSelectedPriority
        default:
          return 'Grey ' + isSelectedPriority
      }
    },
    getSelectedPriority (filterPriority) {
      if (!filterPriority) {
        filterPriority = this.$route.query.ticketsFilter
      }
      var selectedPriorityId = []
      if (filterPriority) {
        if (filterPriority.toLowerCase() === 'servicerequest') {
          selectedPriorityId.push(22)
        } else if (filterPriority.toLowerCase() === 'critical') {
          selectedPriorityId.push(4)
          selectedPriorityId.push(5)
          selectedPriorityId.push(6)
          selectedPriorityId.push(11)
        } else if (filterPriority.toLowerCase() === 'high') {
          selectedPriorityId.push(1)
          selectedPriorityId.push(7)
          selectedPriorityId.push(12)
        } else if (filterPriority.toLowerCase() === 'medium') {
          selectedPriorityId.push(2)
          selectedPriorityId.push(8)
          selectedPriorityId.push(13)
        } else if (filterPriority.toLowerCase() === 'low') {
          selectedPriorityId.push(3)
          selectedPriorityId.push(9)
        } else if (filterPriority.toLowerCase() === 'changes') {
          selectedPriorityId.push(14)
          selectedPriorityId.push(15)
          selectedPriorityId.push(23)
          selectedPriorityId.push(24)
        } else {
          for (let i = 0; i < 25; i++) {
            selectedPriorityId.push(i)
          }
        }
      } else {
        for (let i = 0; i < 25; i++) {
          selectedPriorityId.push(i)
        }
      }
      return selectedPriorityId
    },
    getExclusionStatusList () {
      var exclusionList = []
      var waitingList = [7, 9, 12, 13, 24, 25, 32, 33, 55, 56, 57, 59, 60, 61, 63, 68, 80, 85, 89]
      var inProcessList = [8, 92]
      var serviceRestoredList = [35, 66]
      if (!this.displayCompletedTickets) {
        exclusionList = [5, 16, 69, 72, 93, 94, 95, 99]
      }
      if (!this.displayIncompleteTickets) {
        for (let i = 1; i < 101; i++) {
          if (i !== 5 && i !== 16 && i !== 69 && i !== 72 && i !== 93 && i !== 94 && i !== 95 && i !== 99) {
            exclusionList.push(i)
          }
        }
      }
      if (this.displayWaitingTickets) {
        waitingList.forEach(item => {
          if (exclusionList.includes(item)) {
            var index = exclusionList.indexOf(item)
            if (index !== -1) {
              exclusionList.splice(index, 1)
            }
          }
        })
      }
      if (this.displayInProcessTickets) {
        inProcessList.forEach(item => {
          if (exclusionList.includes(item)) {
            var index = exclusionList.indexOf(item)
            if (index !== -1) {
              exclusionList.splice(index, 1)
            }
          }
        })
      }
      if (this.displayServiceRestoredTickets) {
        serviceRestoredList.forEach(item => {
          if (exclusionList.includes(item)) {
            var index = exclusionList.indexOf(item)
            if (index !== -1) {
              exclusionList.splice(index, 1)
            }
          }
        })
      }
      if (!this.displayWaitingTickets) {
        waitingList.forEach(item => {
          if (!exclusionList.includes(item)) { exclusionList.push(item) }
        })
      }
      if (!this.displayInProcessTickets) {
        inProcessList.forEach(item => {
          if (!exclusionList.includes(item)) { exclusionList.push(item) }
        })
      }
      if (!this.displayServiceRestoredTickets) {
        serviceRestoredList.forEach(item => {
          if (!exclusionList.includes(item)) { exclusionList.push(item) }
        })
      }
      return exclusionList
    },
    applyFilter: function (ticketsFilter) {
      this.$router.push({ path: 'tickets', query: { ticketsFilter } })
      // clear the currently shown tickets
      this.visibleTickets = []
      this.selectedTicket = null
      // recalculate the tickets to display
      this.computeTickets()
    },
    getTicketCount: function (priority) {
      if (!this.ticketList) {
        return 0
      } else {
        if (this.ticketList.length === 0) {
          return 0
        } else {
          if (this.ticketList[0].length === 0) {
            return 0
          } else {
            var completedList = this.getExclusionStatusList()
            if (priority.length > 0) {
              var selectedPriorityList = this.getSelectedPriority(priority)
              return this.ticketList
                .filter(t => selectedPriorityList.includes(t.priorityId) && !completedList.includes(t.statusId)).length
            } else {
              return this.ticketList
                .filter(t => !completedList.includes(t.statusId)).length
            }
          }
        }
      }
    },
    filterTicketStatus (src) {
      if (src === 'incomplete') {
        this.displayIncompleteTickets = !this.displayIncompleteTickets
      }
      if (src === 'completed') {
        this.displayCompletedTickets = !this.displayCompletedTickets
      }
      if (src === 'inprocess') {
        this.displayInProcessTickets = !this.displayInProcessTickets
      }
      if (src === 'waiting') {
        this.displayWaitingTickets = !this.displayWaitingTickets
      }
      if (src === 'servicerestored') {
        this.displayServiceRestoredTickets = !this.displayServiceRestoredTickets
      }
      this.visibleTickets = []
      this.selectedTicket = null
      this.computeTickets()
    },
    setDialog: function (value) {
      this.dialog = value
    },
    getScreenDate (dte) {
      var rtn = ''
      if (dte) {
        var date = new Date(dte)
        var day = this.checkForZero(date.getDate())
        var year = this.checkForZero(date.getFullYear())
        var month = this.checkForZero(date.getMonth() + 1)
        var hours = this.checkForZero(date.getHours())
        var minutes = this.checkForZero(date.getMinutes())
        rtn = day + '-' + month + '-' + year + ' ' + hours + ':' + minutes
      }
      return rtn
    },
    checkForZero (int) {
      if (int.toString().length === 1) {
        int = '0' + int
      }
      return int
    },
    openLinkedTicket: async function () {
      var element = await this.getTicketById(this.ticketLink)
      var tempTicket = {
        id: element.id,
        Title: element.title,
        Ticket_Number: element.ticketNumber,
        Date_Added: this.getScreenDate(element.createDateTime),
        Date_Due: this.getScreenDate(element.dueDateTime),
        Estimated_Hours: element.estimatedHours,
        Issue_Type: this.getIssueTypeFromId(element.issueTypeId),
        Sub_Issue_Type: this.getSubIssueTypeFromId(element.subIssueTypeId),
        Problem_Ticket_Id: element.problemTicketId,
        Last_Activity: this.getScreenDate(element.lastActivityDateTime),
        Status: this.getStatusFromId(element.statusId),
        Customer_Reference: this.getUDFValue(element.id, 'Customer Reference'),
        Priority: this.getPriorityFromId(element.priorityId)
      }
      if (element.accountId != null && element.accountId !== 0) {
        const siteObj = this.$store.getters.site(element.accountId)
        if (siteObj) {
          tempTicket.Site = siteObj.name
        }
      }
      if (element.createdByContactId > 0) {
        var contact = await this.getContactById(element.createdByContactId)
        if (contact.length > 0) {
          tempTicket.Contact_Name = contact[0].firstName + ' ' + contact[0].lastName
          element.createdByName = tempTicket.Contact_Name
        } else {
          tempTicket.Contact_Name = 'Not resolved'
          element.createdByName = 'Not resolved'
        }
      } else {
        tempTicket.Contact_Name = 'Unknown'
        element.createdByName = 'Unknown'
      }
      this.selectedTicket = tempTicket
      this.selected = this.ticketLink
    },
    openTicket: async function (ticketId) {
      this.showDetails = false
      var element = await this.getTicketById(ticketId)
      var tempTicket = {
        id: element.id,
        Title: element.title,
        Ticket_Number: element.ticketNumber,
        Date_Added: this.getScreenDate(element.createDateTime),
        Date_Due: this.getScreenDate(element.dueDateTime),
        Estimated_Hours: element.estimatedHours,
        Issue_Type: this.getIssueTypeFromId(element.issueTypeId),
        Sub_Issue_Type: this.getSubIssueTypeFromId(element.subIssueTypeId),
        Problem_Ticket_Id: element.problemTicketId,
        Last_Activity: this.getScreenDate(element.lastActivityDateTime),
        Status: this.getStatusFromId(element.statusId),
        Customer_Reference: this.getUDFValue(element.id, 'Customer Reference'),
        Priority: this.getPriorityFromId(element.priorityId)
      }
      if (element.accountId != null && element.accountId !== 0) {
        const siteObj = this.$store.getters.site(element.accountId)
        if (siteObj) {
          tempTicket.Site = siteObj.name
        }
      }
      if (element.createdByContactId > 0) {
        var contact = await this.getContactById(element.createdByContactId)
        if (contact.length > 0) {
          tempTicket.Contact_Name = contact[0].firstName + ' ' + contact[0].lastName
        } else {
          tempTicket.Contact_Name = 'Not resolved'
        }
      } else {
        tempTicket.Contact_Name = 'Unknown'
      }
      element.createdByName = tempTicket.Contact_Name
      this.selectedTicket = tempTicket
      this.selectedTicketFull = element
      this.showDetails = true
      this.selected = ticketId
    },
    openQueryTickets () {
      this.$router.push('/query-tickets')
    },
    getUDFValue (id, key) {
      var udf = this.currentTicketUDFs.filter(u => u.id === id)
      for (let i = 0; i < udf.length; i++) {
        if (udf[i].name === key) {
          return udf[i].value
        }
      }
      return ''
    }
  }
}
</script>
<style scoped>
  .openIcon {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
  .openQueryButton {
    margin:0 5px;
  }

  .appSectionContainer {
    margin-bottom: 40px;
  }

  .Brown {
    background-color:  rgb(193, 183, 164);
    border: 2px rgb(155, 139, 116) solid;
  }

  .Blue {
    background-color: rgb(137, 194, 255);
    border: 2px rgb(98, 147, 221) solid;
  }

  .Red {
    background-color: rgb(235, 102, 100);
    border: 2px rgb(241, 31, 27) solid;
  }

  .Amber {
    background-color: rgb(255, 172, 49);
    border: 2px rgb(240, 150, 16) solid;
  }

  .Green {
    background-color: rgb(102, 187, 106);
    border: 2px rgb(60, 165, 65) solid;
  }

  .Grey {
    background-color: rgb(189, 188, 188);
    border: 2px rgb(168, 167, 167) solid;
  }

  .Purple {
    background-color: rgb(182, 110, 241);
    border: 2px rgb(155, 48, 243) solid;
  }

  .priorityFilterContainer {
    display: flex;
  }

  .priorityIcon {
    flex: 1;
    margin: 5px 5px 10px 5px;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    font-weight: 400;
    font-size: 0.86em;
    cursor: pointer;
    box-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
    /* text-shadow: 2px 2px 2px rgb(156 147 147 / 87%); */

  }

  .selected {
    color: #fff;
  }

  .appSectionHeader {
    min-height: 30px;
    margin-bottom: 10px;
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  .completedStatus {
    display:inline-block;
    margin-right: 15px;
  }

  .completedStatusText {
    display:inline-block;
    font-weight: 500;
    vertical-align: top;
  }

  .divSpinner {
    text-align: center;
    margin-top: 60px;
  }

  .pSpinnerText {
    font-weight: bold;
  }
</style>
